import React from 'react';
import { motion } from "framer-motion"
import '../App.css';

export const HourlyWeather = (props) => {
  return (
    <div className="Panel">
      <motion.div id="clear-panel">
        <motion.div className="MarqueeRow">
        </motion.div>
  <div id="clear-footer">{props.hourly}</div>  
      </motion.div>
    </div>
  );
}

export default HourlyWeather
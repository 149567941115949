import React from 'react';
import { motion } from "framer-motion"
import '../Icons.css';


//Sunrise Icon Component
export const SunriseRow = (props) => {
  return (
    <div className="Sunrise">
      <motion.div className = "SunriseCircle">
        <svg width="77" height="77" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="38.5" cy="38.5" r="38.5" fill="#FFE55A"/></svg>
        <motion.div className = "SunriseArrow" animate={{top: -30, opacity: [0, 1, 0], scaleY: [0.2, 1, 0.2] }} transition={{ duration: 3, loop: Infinity}}>
         <svg width="77" height="77" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37 59c0 1.1046.8954 2 2 2s2-.8954 2-2h-4zm3.4142-43.4142c-.781-.7811-2.0474-.7811-2.8284 0L24.8579 28.3137c-.7811.7811-.7811 2.0474 0 2.8284.781.7811 2.0473.7811 2.8284 0L39 19.8284l11.3137 11.3137c.7811.7811 2.0474.7811 2.8284 0 .7811-.781.7811-2.0473 0-2.8284L40.4142 15.5858zM41 59V17h-4v42h4z" fill="#000"/></svg>
        </motion.div>
      </motion.div>
      <div className="SunriseCopy">
        <h2>sunrise</h2>
        <h1>{props.sunrise}</h1>
      </div>
    </div>
  );
}


//Sunset Icon Component
export const SunsetRow = (props) => {
  return (
    <div className="Sunset">
      <motion.div className = "SunsetCircle">
        <svg width="77" height="77" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="38.5" cy="38.5" r="38.5" fill="#FFC75A"/></svg>
        <motion.div className = "SunsetArrow" animate={{top: 28, opacity: [0, 1, 0], scaleY: [0.2, 1, 0.2] }} transition={{ duration: 3, loop: Infinity}}>
         <svg width="77" height="77" fill="none" xmlns="http://www.w3.org/2000/svg"><path d = "M41 18c0-1.1046-.8954-2-2-2s-2 .8954-2 2h4zm-3.4142 43.4142c.781.7811 2.0474.7811 2.8284 0l12.7279-12.7279c.7811-.7811.7811-2.0474 0-2.8284-.781-.7811-2.0473-.7811-2.8284 0L39 57.1716 27.6863 45.8579c-.7811-.7811-2.0474-.7811-2.8284 0-.7811.781-.7811 2.0473 0 2.8284l12.7279 12.7279zM37 18v42h4V18h-4z"
         fill = "#000"/></svg>
        </motion.div>
      </motion.div>
      <div className="SunsetCopy">
        <h2>sunset</h2>
        <h1>{props.sunset}</h1>
      </div>
    </div>
  );
}

//Divider Icon Component
export const Divider = () => {
  return (
      <motion.div className="Divider">
        <svg width="100%" height="2" fill="none" strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 1h302" stroke="#000"/>
</svg>
      </motion.div>
  );
}

//Rain Icon Component
export const Rain = (props) => {
  return (
    <div className="Rain">
      <motion.div className="RainDrop" animate={{top: 100, opacity: [0, 1, 0], scale: [1, 0.2] }} transition={{ duration: 3, loop: Infinity, ease: "easeInOut"}}>
        <svg width="62" height="78" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M62 46.2106C62 63.4913 48.1208 77.5 31 77.5S0 63.4913 0 46.2106C0 28.93 31 0 31 0s31 28.93 31 46.2106z" fill="#6ECBFF"/>
</svg>
      </motion.div>
  <div className="RainCopy">
        <h2>rain</h2>
  <h1>{props.rain}</h1>
      </div>
  </div>
  );
}

//Humidity Icon Component
export const Humidity = (props) => {
  return (
    <div className="Humidity">
      <motion.div className ="Cloud" animate={{ scale: 1.2, opacity: 1 }} transition={{ duration: 0.45,
            ease: "linear",
            yoyo: Infinity,
            repeatDelay: 0.9,}}>
         <svg width="79" height="59" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M29.5997 1c6.527 0 12.1055 3.75725 14.3475 9.0618 1.695-.59391 3.5328-.91901 5.4528-.91901 6.7067 0 12.4122 3.96711 14.5251 9.50381 8.2341 2.9433 14.0748 10.3387 14.0748 18.9963 0 11.2429-9.8497 20.3571-22 20.3571h-33C10.8497 58 1 48.8858 1 37.6429c0-8.5184 5.65424-15.8147 13.6793-18.8508-.3131-1.1325-.4795-2.3193-.4795-3.5421C14.1998 7.37994 21.0946 1 29.5997 1z" fill="#F0F0F0"/>
  <path d="M43.9472 10.0618l-.7731.3268.3068.7259.7438-.2606-.2775-.7921zm19.9779 8.5848l-.7841.2992.1377.3611.3639.13.2825-.7903zM55.9764 58l.0008-.8393h-.0008V58zm-32.9529 0v-.8393h-.0008l.0008.8393zm-8.3442-39.2079l.297.785.7159-.2709-.204-.7377-.8089.2236zm30.041-9.057C42.3399 4.10298 36.4452.160715 29.5997.160715V1.83929c6.2084 0 11.4709 3.57223 13.5744 8.54931l1.5462-.6535zM49.4 8.30351c-2.015 0-3.9467.34125-5.7304.96626l.5551 1.58413c1.6064-.5629 3.3504-.87182 5.1753-.87182V8.30351zM64.7092 18.3474C62.4647 12.4659 56.4333 8.30351 49.4 8.30351v1.67857c6.3802 0 11.7596 3.77192 13.741 8.96372l1.5682-.5984zm14.13 19.2955c0-9.056-6.1067-16.7393-14.6316-19.7866l-.565 1.5806c7.9433 2.8394 13.518 9.9467 13.518 18.206h1.6786zM55.9999 58.8393c12.5513 0 22.8393-9.4298 22.8393-21.1964h-1.6786c0 10.7192-9.4114 19.5178-21.1607 19.5178v1.6786zm-.0244 0h.0244v-1.6786h-.0227l-.0017 1.6786zm.0009-1.6786H23.0235v1.6786h32.9529v-1.6786zm-32.9765 1.6786h.0244l-.0016-1.6786h-.0228v1.6786zM.160715 37.6429c0 11.7666 10.287985 21.1964 22.839185 21.1964v-1.6786c-11.7492 0-21.16061-8.7986-21.16061-19.5178H.160715zM14.3823 18.0071C6.0727 21.1509.160715 28.7329.160715 37.6429H1.83929c0-8.1267 5.39648-15.1374 13.13701-18.0658l-.594-1.57zM13.3605 15.25c0 1.2994.1769 2.5614.5098 3.7657l1.6179-.4472c-.2932-1.0607-.4491-2.1723-.4491-3.3185h-1.6786zM29.5997.160715c-8.9061 0-16.2392 6.695555-16.2392 15.089285h1.6786c0-7.34639 6.4565-13.41071 14.5606-13.41071V.160715z" fill="#000" stroke="#000" strokeWidth="1"/></svg>
        </motion.div>
      < motion.div className = "RainDroplet">
        <svg width="77" height="77" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M50.4 35.6955C50.4 41.9388 45.3856 47 39.2 47 33.0144 47 28 41.9388 28 35.6955 28 29.4521 39.2 19 39.2 19s11.2 10.4521 11.2 16.6955z" fill="#6ECBFF"/></svg>
      </motion.div>
      <div className="HumidityCopy">
        <h2>humidity</h2>
        <h1>{props.humidity}</h1>
      </div>
    </div>
  );
}

//Wind Icon Component
export const Wind = (props) => {
  return (
    <div className="Wind">
      <motion.div className="WindIcon">
        <svg width="77" height="70" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 10l8.34472 3.5509C19.1932 17.529 29.9656 16.1054 37.9598 9.83548v0c7.7699-6.09406 18.1842-7.62213 27.3773-4.01701L76 10" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
  <path d="M1.5 20l8.34472 3.5509c9.34848 3.9781 20.12088 2.5545 28.11508-3.7154v0c7.7699-6.0941 18.1842-7.6222 27.3773-4.017L76 20" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
  <path d="M1.5 30l8.34472 3.5509c9.34848 3.9781 20.12088 2.5545 28.11508-3.7154v0c7.7699-6.0941 18.1842-7.6222 27.3773-4.017L76 30" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
  <path d="M1.5 40l8.34472 3.5509c9.34848 3.9781 20.12088 2.5545 28.11508-3.7154v0c7.7699-6.0941 18.1842-7.6222 27.3773-4.017L76 40" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
  <path d="M1.5 50l8.34472 3.5509c9.34848 3.9781 20.12088 2.5545 28.11508-3.7154v0c7.7699-6.0941 18.1842-7.6222 27.3773-4.017L76 50" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
  <path d="M1.5 60l8.34472 3.5509c9.34848 3.9781 20.12088 2.5545 28.11508-3.7154v0c7.7699-6.0941 18.1842-7.6222 27.3773-4.017L76 60" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
</svg>
      </motion.div>
      <div className="WindCopy">
        <h2>wind</h2>
  <h1>{props.wind}</h1>
      </div>
    </div>
  );
}

//UV Index Icon Component
export const UVIndex = (props) => {
  return (
    <div className="UV">
      <div className="UVRings">
      <motion.div className = "OuterCircle" animate={{ rotate: 360 }} transition={{ duration: 2,
            ease: "linear", loop: Infinity}}>
        <svg width="77" height="77" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="38.5" cy="38.5" r="35.5" stroke="#000" strokeWidth="6" strokeDasharray="4 4"/>
</svg>
        <motion.div className= "InnerCircle"
        animate={
          {
            rotate: 180,
            backgroundColor: ["#7FFFD4", "#FFE55A"]
          }
        }
        transition={
            {
            duration: 1,
            ease: "easeInOut",
            yoyo: "Infinity",
        }}>
         <svg className="UVInnerCircle" width="77" height="77" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="39" cy="39" r="21" fill="#FF00FF"/>
</svg>
        </motion.div>
      </motion.div>
  </div>
      <div className="UVCopy">
        <h2>uv index</h2>
      <h1>{props.uv}</h1>
      </div>
    </div>
  );
}

//Air Quality Index Icon Component
export const AirQuality = (props) => {
  return (
    <div className="AQ">
      <motion.div className = "AirOuterCircle" animate={{ scale: [0.5, 1.2] }} transition={{ duration: 6,
            ease: "easeInOut", yoyo: Infinity}}>
        <svg width="81" height="81" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="40.5" cy="40.5" r="38.5" stroke="#000" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 10"/>
</svg>
      </motion.div>
      <div className="AirQualityCopy">
        <h2>air quality</h2>
      <h1>{props.airquality}</h1>
      </div>
    </div>
  );
}
import React from 'react';
import './App.css';
import CurrentWeather from './components/currentPanel.js';
import HourlyWeather from './components/HourlyPanel.js';
import DailyWeather from './components/DailyPanel.js';
import {SunriseRow, SunsetRow, Divider, Rain, Humidity, Wind, UVIndex, AirQuality} from './components/AnimatedIcons.js';



function isNight() {
  var date = new Date();
  return (date.getHours() > 17 || date.getHours() < 6);
}
console.log(isNight());



function Unix_timestamp(t) {

  var dt = new Date(t * 1000);
  var hr = dt.getHours();
  var m = "0" + dt.getMinutes();
  return hr + ':' + m.substr(-2)
}

class App extends React.Component {

  
  state = {
    temperature: 0,
    city: "Enter a city below",
    country: undefined,
    humidity: "0",
    description: undefined,
    minTemp: "0",
    maxTemp: "0",
    sunrise: "",
    sunset: "",
    wind: "",
    rain: "chance of rain",
    uv: "0",
    airquality: "0",
    newsText: "The news is the news.",

    hourly: "Today is your day.",
    daily: "This week is your week.",

    error: "Not a real city, sorry."
  }

  

  getWeather = async (e) => {
  
    e.preventDefault();
    const city = e.target.elements.city.value;
    const api_call = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${process.env.REACT_APP_OP_KEY}&units=imperial`);
    const data = await api_call.json();

    let lon
    let lat

    lon = data.coord.lon
    lat = data.coord.lat

    const uvcall = await fetch(`https://api.openweathermap.org/data/2.5/uvi?appid=${process.env.REACT_APP_OP_KEY}&lat=${lat}&lon=${lon}`);
    const uvdata = await uvcall.json();
    // console.log(uvdata);


    const airqcall = await fetch(`https://api.waqi.info/feed/${city}/?token=${process.env.REACT_APP_AQI_KEY}`);
    const airqdata = await airqcall.json();
    // console.log(airqdata);

    const darkcall = await fetch(`https://cors-anywhere.herokuapp.com/https://api.darksky.net/forecast/${process.env.REACT_APP_D_KEY}/${lat},${lon}`);
    const darkdata = await darkcall.json();
    // console.log(darkdata);



    if (city) {
      console.log(data)
  
      this.setState({
        temperature: data.main.temp,
        city: data.name,
        humidity: data.main.humidity,
        description: data.weather[0].description,
        minTemp: data.main.temp_min,
        maxTemp: data.main.temp_max,
        sunrise: data.sys.sunrise,
        sunset: data.sys.sunset,
        wind: data.wind.speed,
        uv: uvdata.value,
        airquality: airqdata.data.aqi,
        rain: data.weather[0].id,
        // rain: data.rain['1h'],
        hourly: darkdata.hourly.summary,
        daily: darkdata.daily.summary,
        error: "Whoops!"
      })

      

      let dailyConditions = document.querySelector("#Circle");
      let hourlyConditions = document.querySelector("#clear-panel");
      let currentConditions = document.querySelector("#panel-background");
      let tempColor = document.querySelector("#temp");
      let descriptionColor = document.querySelector("#description");
      let cloud1 = document.querySelector(".Clouds1");
      let cloud2 = document.querySelector(".Clouds2");
      let cloud3 = document.querySelector(".Clouds3");
      let cloud4 = document.querySelector(".Clouds4");
      let mist = document.querySelector(".Mist");
      let umbrella = document.querySelector(".umbrella-icon-white");
      // let umbrellaWhite = document.querySelector(".umbrella-icon-white");
      let umbrellaText = document.querySelector("#umbrella-text");
      let sweater = document.querySelector(".sweater-icon-white");
      // let sweaterWhite = document.querySelector(".sweater-icon-white");
      let sweaterText = document.querySelector("#sweater-text");
      let scarf = document.querySelector(".scarf-icon-white");
      // let scarfWhite = document.querySelector(".scarf-icon-white");
      let scarfText = document.querySelector("#scarf-text");
      let currentFilter = document.querySelector("#panel-background-filter")


      let currentTime = darkdata.currently.time
      let fixedTime = Unix_timestamp((currentTime))
      let time = isNight(fixedTime)
      console.log(time)
      
      if (time) {
        currentFilter.style.opacity = "1"
        tempColor.style.color = "#FFFFFF"
        descriptionColor.style.color = "#FFFFFF"
      }
      else {
        currentFilter.style.opacity = "0"
        tempColor.style.color = "#000000"
        descriptionColor.style.color = "#000000"
      }

      if ((data.weather[0].description).includes("overcast")) {
        currentConditions.style.backgroundColor = "#D6D6D6"
        cloud1.style.opacity = "1"
        cloud2.style.opacity = "1"
        cloud3.style.opacity = "1"
        cloud4.style.opacity = "0"
        mist.style.opacity = "1"
      } else {
        if ((data.weather[0].description).includes("moderate rain")) {
          currentConditions.style.backgroundColor = "#0055B6"
          tempColor.style.color = "#FFFFFF"
          descriptionColor.style.color = "#FFFFFF"
          cloud1.style.visibility = "visible"
          cloud2.style.visibility = "visible"
          cloud3.style.visibility = "visible"
          cloud4.style.visibility = "hidden"
        } else if ((data.weather[0].description).includes("snow")) {
          currentConditions.style.backgroundColor = "#F8F8F8"
          cloud1.style.visibility = "visible"
          cloud2.style.visibility = "visible"
          cloud3.style.visibility = "visible"
          cloud4.style.visibility = "visible"
          mist.style.visibility = "visible"
        } else if ((data.weather[0].description).includes("light rain")) {
          currentConditions.style.backgroundColor = "#638EBF"
          cloud1.style.visibility = "visible"
          cloud2.style.visibility = "visible"
          cloud3.style.visibility = "visible"
          cloud4.style.visibility = "hidden"
          mist.style.visibility = "hidden"
        } else if ((data.weather[0].description).includes("clouds")) {
          currentConditions.style.backgroundColor = "#73BCFF"
          cloud1.style.visibility = "visible"
          cloud2.style.visibility = "visible"
          cloud3.style.visibility = "visible"
          cloud4.style.visibility = "hidden"
          mist.style.visibility = "visible"
        } else if ((data.weather[0].description).includes("clear sky")) {
          currentConditions.style.backgroundColor = "#92E0FF"
          cloud1.style.visibility = "hidden"
          cloud2.style.visibility = "hidden"
          cloud3.style.visibility = "hidden"
          cloud4.style.visibility = "hidden"
          mist.style.opacity = "0"
        } else if ((data.weather[0].description).includes("thunderstorm") || (data.weather[0].description).includes("thunderstorm with light rain")) {
          currentConditions.style.backgroundColor = "#274244"
          cloud1.style.visibility = "visible"
          cloud2.style.visibility = "visible"
          cloud3.style.visibility = "visible"
          cloud4.style.visibility = "visible"
          mist.style.visibility = "visible"
        } else if ((data.weather[0].description).includes("light snow") || (data.weather[0].description).includes("thunderstorm with light rain")) {
          currentConditions.style.backgroundColor = "#274244"
          cloud1.style.visibility = "visible"
          cloud2.style.visibility = "visible"
          cloud3.style.visibility = "visible"
          cloud4.style.visibility = "visible"
          mist.style.visibility = "visible"
        }
  
      }


      if ((darkdata.daily.summary).includes("Monday")) {
        dailyConditions.style.left = "5%"
      } else {
        if ((darkdata.daily.summary).includes("Tuesday")) {
          dailyConditions.style.left = "10%"
        } else if ((darkdata.daily.summary).includes("Wednesday")) {
          dailyConditions.style.left = "35%"
        } else if ((darkdata.daily.summary).includes("Thursday")) {
          dailyConditions.style.left = "38%"
        } else if ((darkdata.daily.summary).includes("Friday")) {
          dailyConditions.style.left = "65%"
        } else if ((darkdata.daily.summary).includes("Saturday")) {
          dailyConditions.style.left = "60%"
        } else if ((darkdata.daily.summary).includes("Sunday")) {
          dailyConditions.style.left = "80%"
        }
      };

      
      if ((darkdata.hourly.summary).includes("snow") || (darkdata.hourly.summary).includes("Snow") || (darkdata.hourly.summary).includes("light snow") || (darkdata.hourly.summary).includes("heavy snow") || (data.weather[0].description).includes("snow")) {
          hourlyConditions.style.backgroundColor = "#FFFFFF"
          umbrellaText.style.opacity = 0
          scarf.style.opacity = 1
          sweater.style.opacity = 0
          umbrella.style.opacity = 0
          sweaterText.style.opacity = 0
          scarfText.style.opacity = 0
      } else {
         if ((darkdata.hourly.summary).includes("rain") || (darkdata.hourly.summary).includes("Rain")) {
           hourlyConditions.style.backgroundColor = "#6ECBFF"
           umbrellaText.style.opacity = 0
           scarf.style.opacity = 0
           sweater.style.opacity = 0
           umbrella.style.opacity = 1
           sweaterText.style.opacity = 0
           scarfText.style.opacity = 0
      } else if ((darkdata.hourly.summary).includes("cloud") || (darkdata.hourly.summary).includes("Cloud")) {
           hourlyConditions.style.backgroundColor = "#FDFDFD"
           umbrellaText.style.opacity = 0
           scarf.style.opacity = 0
           sweater.style.opacity = 0
           umbrella.style.opacity = 0
           sweaterText.style.opacity = 0
           scarfText.style.opacity = 0
      } else if ((darkdata.hourly.summary).includes("sun") || (darkdata.hourly.summary).includes("Sun")) {
           hourlyConditions.style.backgroundColor = "#FFE55A"
           umbrellaText.style.opacity = 0
           scarf.style.opacity = 0
           sweater.style.opacity = 0
           umbrella.style.opacity = 0
           sweaterText.style.opacity = 0
           scarfText.style.opacity = 0
      } else if ((darkdata.hourly.summary).includes("clear") || (darkdata.hourly.summary).includes("Clear")) {
           hourlyConditions.style.backgroundColor = "#92E0FF"
           umbrellaText.style.opacity = 0
           scarf.style.opacity = 0
           sweater.style.opacity = 0
           umbrella.style.opacity = 0
           sweaterText.style.opacity = 0
           scarfText.style.opacity = 0
      } else if ((darkdata.hourly.summary).includes("overcast") || (darkdata.hourly.summary).includes("Overcast")) {
        hourlyConditions.style.backgroundColor = "#D6D6D6"
        umbrellaText.style.opacity = 1
        scarf.style.opacity = 0
        sweater.style.opacity = 0
        umbrella.style.opacity = 0
        sweaterText.style.opacity = 0
        scarfText.style.opacity = 0
      } else if ((darkdata.hourly.summary).includes("fog") || (darkdata.hourly.summary).includes("Fog")) {
        hourlyConditions.style.backgroundColor = "#D6D6D6"
        umbrellaText.style.opacity = 0
        scarf.style.opacity = 0
        sweater.style.opacity = 0
        umbrella.style.opacity = 0
        sweaterText.style.opacity = 0
        scarfText.style.opacity = 0
      }
    };
      
       if (data.weather[0].main !== "Rain") {
         this.setState({
           rain: "0%"
         })
       } else {
         if (data.weather[0].id === "500") {
           this.setState({
             rain: "10%"
           })
         } else if (data.weather[0].id === "501") {
           this.setState({
             rain: "10%"
           })
         } else if (data.weather[0].id === "502") {
           this.setState({
             rain: "90%"
           })
         } else if (data.weather[0].id === "503") {
           this.setState({
             rain: "80%"
           })
         } else if (data.weather[0].id === "504") {
           this.setState({
             rain: "70%"
           })
         } else if (data.weather[0].id === "511") {
           this.setState({
             rain: "70%"
           })
         } else if (data.weather[0].id === "520") {
           this.setState({
             rain: "40%"
           })
         } else if (data.weather[0].id === "521") {
           this.setState({
             rain: "30%"
           })
         } else if (data.weather[0].id === "522") {
           this.setState({
             rain: "65%"
           })
         } else if (data.weather[0].id === "531") {
           this.setState({
             rain: "75%"
           })
         }
       };

       if (airqdata.data.aqi <= "50") {
         this.setState({
           airquality: "good"
         })
       } else {
         if (airqdata.data.aqi <= "100" && airqdata.data.aqi >= "51") {
           this.setState({
             airquality: "moderate"
           })
         } else if (airqdata.data.aqi <= "150" && airqdata.data.aqi >= "101") {
           this.setState({
             airquality: "unhealthy"
           })
         } else if (airqdata.data.aqi <= "200" && airqdata.data.aqi >= "151") {
           this.setState({
             airquality: "unhealthy"
           })
         } else if (airqdata.data.aqi <= "300" && airqdata.data.aqi >= "201") {
           this.setState({
              airquality: " very unhealthy"
            })
         } else if (airqdata.data.aqi <= "500" && airqdata.data.aqi >= "301") {
           this.setState({
             airquality: " hazardous"
           })
      }
    }
           
    
    } else {
      this.setState({
        temperature: undefined,
        city: undefined,
        humidity: undefined,
        description: undefined,
        minTemp: undefined,
        maxTemp: undefined,
        sunrise: undefined,
        sunset: undefined,
        wind: undefined,
        uv: undefined,
        airquality: undefined,
        hourly: undefined,
        daily:undefined,
        // newsText: undefined,
        // newsPhoto:undefined,
        error: undefined
      });
    }
  }
  render() {
  return (
    <div className="Application">
      <div className="TodayInfo">
      <div className="Subhead">
        <h3>Today</h3>
        <p className="HiLow">{Math.floor(this.state.maxTemp)}⇡ {Math.floor(this.state.minTemp)}⇣</p>
        </div>
        <Divider />
        <div className="Icons">
          <SunriseRow sunrise={Unix_timestamp(this.state.sunrise) + "am"}/>
          <SunsetRow sunset={Unix_timestamp(this.state.sunset) + "pm"}/>
          <Rain rain={(this.state.rain)}/>
          <Humidity humidity={(this.state.humidity) + "%"}/>
          <Wind wind={(this.state.wind) +  "mph"}/>
          <UVIndex uv={this.state.uv}/>
          <AirQuality airquality={this.state.airquality}/>
          </div>
          </div>


      <HourlyWeather hourly={this.state.hourly}/>
      <DailyWeather daily={this.state.daily}/>
      
      <form className="Search" onSubmit={this.getWeather}>
        <input id="city-search" placeholder="City, Please" name="city" type="text" autocomplete="on"/>
        <button id="SearchButton">Go</button> 
        </form>
      <header className="App-background">
        <div className="location-date">
          <p id="location">{this.state.city}</p>
          <div id="date">°F</div>
        </div>
      </header>
    <CurrentWeather temperature ={
      Math.floor(this.state.temperature) + "°"
    } description={this.state.description}
    />
    </div>
  );
}
}

export default App;
